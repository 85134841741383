<template>
  <div @keydown.enter="handleEnter">
    <card class="p-3">
      <h1 class="page-header mb-lg-3 card-title">Multi Factor Authentication (MFA)</h1>
      <p class="text-center twoFa-para">Enter the MFA code from your email</p>
      <SimpleOtpInput
        type="text"
        :length="otpLength"
        v-model="otp"
        @change="handleOtpChange"
        @complete="handleOtpComplete"
      />
      <button class="btn btn-outline btn-primary btn-block font-weight-bold mt-4" :disabled="!isOtpComplete" @click="verifyOtp">Verify</button>

      <div class="mt-4 text-center font-weight-bold">
        <router-link :to="{ name: 'Login' }">Return to Sign in</router-link>
      </div>

      <div class="mt-6 text-center font-weight-bold">
        <a @click="resendEmail">Resend email</a>
      </div>
    </card>
  </div>
</template>

<script>
import SimpleOtpInput from "vue-simple-otp-input";
import 'vue-simple-otp-input/dist/vue-simple-otp-input.css';
import {mapActions} from 'vuex';
import {
  VERIFY_MFA,
  SEND_MFA_EMAIL,
} from '@/store/storeActions';
import {getCookie} from '../../util/appLocalStorage';

export default {
  components: {
    SimpleOtpInput,
  },
  data() {
    return {
      otp: '',
      otpLength: 6, 
      loggedInUserEmail: null,
      captchaToken: null,
    }
  },
  computed: {
    // Checks if the entered OTP matches the required length.
    isOtpComplete() {
      const otpPattern = new RegExp(`^\\d{${this.otpLength}}$`);
      return otpPattern.test(this.otp);
    },
  },
  methods: {
    ...mapActions([
      VERIFY_MFA,
      SEND_MFA_EMAIL,
    ]),
    // Updates the OTP value as the user types
    handleOtpChange(newOtp) {
      this.otp = newOtp;
    },
    // Stores the final OTP when the user completes input
    handleOtpComplete(newOtp) {
      this.otp = newOtp;  // Store the final OTP
    },
    // Calls the VERIFY_MFA action with the user's email and entered OTP
    verifyOtp() {
      if (!this.isOtpComplete) return;

      const data = {
        email: this.loggedInUserEmail,
        code: this.otp,
      };
      this.VERIFY_MFA(data);
    },
    // Method to handle the Enter key press event
    handleEnter() {
      if (this.isOtpComplete) {
        this.verifyOtp();
      }
    },
    // Resends the MFA email and resets the OTP input
    async resendEmail() {
      this.otp = '';
      this.captchaToken =  await this.executeRecaptcha();
      const resetEmailData = {
        email: this.loggedInUserEmail,
        captchaToken:this.captchaToken,
      };
      this.SEND_MFA_EMAIL(resetEmailData);
    },
    // Executes Google reCAPTCHA verification for added security
    executeRecaptcha() {
      return new Promise((resolve, reject) => {
        grecaptcha.ready(() => {
          grecaptcha.execute(process.env.VUE_APP_GOOGLE_CAPTCHA_KEY, {action: 'two_factor_authentication'}).
              then(resolve).
              catch(reject);
        });
      });
    },
  }, 
  mounted() {
    // Fetches the logged-in user's email from cookies after a short delay
    setTimeout(() => {
      this.loggedInUserEmail = getCookie('loggedInUserEmail');
    }, 100);
  }
}
</script>
<style>
.otp-single-input {
  padding: 4px;
  width: 2em;
  height: 2em;
  text-align: center;
  border: none;
  border-bottom: 3px solid black;
}
.otp-single-input:focus {
  border: none;
  border-bottom: 3px solid #8301AB;
  outline: none;
}
.single-input-container {
  margin: 10px;
}
.twoFa-para {
  color: black;
  font-size: 17px;
}
</style>
